<template>
  <i class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 9.67015C21.9368 9.48723 21.822 9.32657 21.6693 9.20765C21.5167 9.08872 21.3328 9.01664 21.14 9.00014L15.45 8.17015L12.9 3.00015C12.8181 2.83107 12.6902 2.68849 12.5311 2.58872C12.3719 2.48895 12.1878 2.43604 12 2.43604C11.8121 2.43604 11.6281 2.48895 11.4689 2.58872C11.3097 2.68849 11.1819 2.83107 11.1 3.00015L8.54998 8.16014L2.85998 9.00014C2.6749 9.02645 2.5009 9.10411 2.35773 9.2243C2.21455 9.3445 2.10794 9.50242 2.04998 9.68015C1.99692 9.85382 1.99216 10.0387 2.03621 10.2148C2.08025 10.391 2.17144 10.5519 2.29998 10.6801L6.42998 14.6801L5.42998 20.3601C5.39428 20.5476 5.41297 20.7414 5.48385 20.9186C5.55473 21.0958 5.67483 21.249 5.82998 21.3601C5.98119 21.4683 6.15955 21.5321 6.34503 21.5444C6.5305 21.5568 6.71575 21.5172 6.87998 21.4301L12 18.7601L17.1 21.4401C17.2403 21.5193 17.3988 21.5607 17.56 21.5601C17.7718 21.5609 17.9784 21.4944 18.15 21.3701C18.3051 21.259 18.4252 21.1058 18.4961 20.9286C18.567 20.7514 18.5857 20.5576 18.55 20.3701L17.55 14.6901L21.68 10.6901C21.8244 10.5678 21.9311 10.407 21.9877 10.2265C22.0444 10.0459 22.0486 9.85302 22 9.67015ZM15.85 13.6701C15.7327 13.7836 15.645 13.924 15.5944 14.0791C15.5439 14.2343 15.532 14.3994 15.56 14.5601L16.28 18.7501L12.52 16.7501C12.3753 16.6731 12.2139 16.6328 12.05 16.6328C11.8861 16.6328 11.7247 16.6731 11.58 16.7501L7.81998 18.7501L8.53998 14.5601C8.56791 14.3994 8.55609 14.2343 8.50554 14.0791C8.45499 13.924 8.36725 13.7836 8.24998 13.6701L5.24998 10.6701L9.45998 10.0601C9.62198 10.0376 9.77598 9.97569 9.90848 9.8798C10.041 9.78391 10.1479 9.65698 10.22 9.51015L12 5.70015L13.88 9.52015C13.952 9.66698 14.059 9.79391 14.1915 9.8898C14.324 9.98569 14.478 10.0476 14.64 10.0701L18.85 10.6801L15.85 13.6701Z"
        fill="#0377C4"
      />
    </svg>
  </i>
</template>

<script>
export default {
  name: 'StarIcon'
}
</script>

<template>
  <main class="main">
    <div class="main__inner">
      <section class="section srv-catalog">
        <h2>Каталог услуг</h2>
        <div class="srv-catalog__container">
          <div class="srv-catalog__content">
            <form class="filter__form services__form" autocomplete="off">
              <div class="row search">
                <div class="col-12 search__input">
                  <div class="search__input__wrap">
                    <input
                      v-model.trim="form.title.value"
                      id="news_search"
                      type="text"
                      placeholder="Введите название услуги для интерактивного поиска"
                      maxlength="255"
                      @input="requestSearch"
                    />
                    <button v-if="form.title.value" type="button" @click="resetSearchInput">
                      <CloseIcon />
                    </button>
                    <div v-if="form.title.value" class="search__result-dropdown">
                      <LoadingIndicator v-if="loading" title="Загрузка" />
                      <div v-else-if="searchResults && searchResults.length">
                        <ul>
                          <li v-for="(item, index) in searchResults" :key="index" class="search__result-item">
                            <router-link
                              :to="{
                                name: 'service',
                                params: { id: item.id },
                              }"
                            >
                              {{ item.title }}
                            </router-link>
                          </li>
                        </ul>
                      </div>
                      <div v-else>Ничего не найдено</div>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <LoadingIndicator v-if="pageLoading" title="Загрузка" />
            <div
              class="srv-catalog__categories"
              :class="{ 'srv-catalog__categories--active': childCategories && childCategories.length }"
              v-else-if="servicesCategories && servicesCategories.length"
            >
              <div class="srv-catalog__categories-container">
                <div
                  class="srv-catalog__wrap-container srv-catalog__wrap-container--child"
                  v-if="childCategories && childCategories.length"
                >
                  <ul class="srv-catalog__wrap">
                    <li v-for="(item, index) in childCategories" :key="index">
                      <a
                        @click.prevent="showServices(item.id)"
                        :class="{ active: isCategoryActive(item.id, false) }"
                        class="srv-catalog__item link"
                        href=""
                      >
                        <span class="srv-catalog__title">{{ item.title }}</span>
                        <ArrowRightIcon />
                      </a>
                    </li>
                  </ul>
                  <Multiselect
                    track-by="id"
                    label="title"
                    class="srv-catalog__ms"
                    :value="selectedChildCategory"
                    :options="childCategories"
                    @input="showServices($event.id)"
                    v-if="childCategories && childCategories.length"
                    placeholder="Выберите из списка"
                    selectLabel="Выбрать ↵"
                    deselectLabel="Удалить ↵"
                    selectedLabel="Выбрано"
                    :searchable="true"
                    :allow-empty="false"
                  >
                    <div class="srv-catalog__ms-label" slot="singleLabel" v-if="selectedChildCategory">
                      <i
                        class="srv-catalog__ms-icon"
                        v-if="selectedChildCategory.icon"
                        v-html="selectedChildCategory.icon"
                      ></i>
                      <span class="srv-catalog__ms-title">{{ selectedChildCategory.title }}</span>
                    </div>
                    <span slot="noOptions">Список пуст</span>
                  </Multiselect>
                </div>
              </div>
              <ul class="srv-catalog__wrap" v-if="selectedServices && selectedServices.length">
                <li v-for="(item, index) in selectedServices" :key="index">
                  <router-link :to="{ name: 'service', params: { id: item.id } }" class="srv-catalog__link link">
                    <div class="srv-catalog__type" v-if="item.is_electronic_service">Электронная услуга</div>
                    <div class="srv-catalog__title">{{ item.subtitle }}</div>
                  </router-link>
                </li>
              </ul>
            </div>
            <p v-else class="_item__text">Услуг нет</p>
          </div>
          <BannersComponent
            v-if="banners && banners.data"
            :banners="banners.data"
          />
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import CloseIcon from '@/components/svg/CloseIcon.vue'
import ArrowRightIcon from '@/components/svg/ArrowRightSmall.vue'
import StarIcon from '@/components/svg/StarIcon.vue'
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import BannersComponent from '@/components/BannersComponent.vue'
import FILTER_SERVICES from '@/graphql/services/FilterServices.graphql'
import Multiselect from 'vue-multiselect'

export default {
  name: 'DepartmentServicesPage',
  // async asyncData ({ store }) {
  //   await store.dispatch('GET_SERVICES_PAGE').then(({ data }) => {
  //     this.pageLoading = false
  //   })
  // },
  data () {
    return {
      loading: false,
      pageLoading: true,
      searchResults: [],
      filterData: {},
      form: {
        title: {
          value: null,
          message: null
        }
      },
      selectedParentCategory: null,
      selectedChildCategory: null,
      selectedServices: []
    }
  },
  created () {
    this.$store.dispatch('GET_SERVICES_PAGE', this.$apollo.provider).finally(() => {
      this.pageLoading = false
      this.showServices(-1)
    })
  },
  computed: {
    banners () {
      return this.$store.state.banners
    },
    /**
     * Возвращать только категории с услугами
     */
    servicesCategories () {
      return this.$store.state.category_services.filter(
        (item) =>
          (item.services && item.services.length) ||
          (item.child_categories &&
            item.child_categories.length &&
            item.child_categories.filter((i) => i.services && i.services.length).length)
      )
    },
    /**
     * Все популярные услуги
     */
    popularServices () {
      return this.$store.state.popular_services
    },
    childCategories () {
      let childList = []
      this.$store.state.category_services.forEach((item) => {
        if (item.child_categories && item.child_categories.length) {
          childList.push(...item.child_categories)
        }
      })
      let popular = []
      if (this.popularServices && this.popularServices.length) {
        popular = [
          {
            id: -1,
            title: 'Популярное',
            icon: StarIcon
          }
        ]
      }
      childList = childList.filter(category => {
        return category.services && category.services.length
      })
      childList = popular.concat(childList)
      return childList
    }
  },
  methods: {
    requestSearch () {
      if (this.form.title.value.length) {
        clearTimeout(this.timeout)
        this.loading = true
        this.timeout = setTimeout(() => {
          this.load()
        }, 500)
      }
    },
    async load () {
      this.loading = true
      this.filterData = {}
      Object.keys(this.form).forEach((key) => {
        if (this.form[key].value !== null) this.filterData[key] = this.form[key].value
      })
      await this.$apollo.provider.clients.dept
        .query({
          query: FILTER_SERVICES,
          variables: {
            ...this.filterData
          }
        })
        .then(({ data }) => {
          this.loading = false
          if (data.filter_services) {
            this.searchResults = data.filter_services
          }
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
        })
    },
    showServices (id) {
      if (id === -1) {
        this.selectedChildCategory = {
          id: -1,
          title: 'Популярное'
        }
        this.selectedServices = this.popularServices
      } else {
        this.selectedChildCategory = this.childCategories.find((item) => item.id === id)
        if (this.selectedChildCategory.services) {
          this.selectedServices = this.selectedChildCategory.services
        }
      }
    },
    isCategoryActive (id, isParent) {
      if (isParent && this.selectedParentCategory) {
        return id === this.selectedParentCategory.id
      }
      if (!isParent && this.selectedChildCategory) {
        return id === this.selectedChildCategory.id
      }
      return false
    },
    /**
     * Очищение строки поиска
     */
    resetSearchInput () {
      this.form.title.value = null
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$store.state.banners = {}
    next()
  },
  metaInfo () {
    return this.$seo(
      'common',
      'Официальный сайт Главы города Махачкалы',
      '',
      '',
      'Официальный сайт Главы города Махачкалы',
      '',
      ''
    )
  },
  jsonld () {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization'
    }
  },
  components: {
    CloseIcon,
    ArrowRightIcon,
    LoadingIndicator,
    Multiselect,
    BannersComponent
  }
}
</script>

<style lang="stylus">
@import '~vue-multiselect/dist/vue-multiselect.min.css'
@import "~@/styles/parts/services_catalog.styl"
</style>
